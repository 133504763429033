/* eslint-disable max-len */

import React from 'react'
import BoomImg from 'assets/Articles/boom.png'
import LoveCollectionImg from 'assets/Articles/loveCollection.png'
import LiveLocationImg from 'assets/Articles/liveLocation.png'
import AllNewChatImg from 'assets/Articles/allNewChat.png'
import LunarNewYearImg from 'assets/Articles/lunarNewYear.png'
import ThreeDMapsImg from 'assets/Articles/threeDMaps.png'
// import PostingImagesImg from 'assets/Articles/postingImages.png'
// import FreemiumEmojisImg from 'assets/Articles/freemiumEmojis.png'
// import { Text, Title, Image, List } from 'pages/Article/components/ArticleComponents'
import { Text } from 'pages/Article/components/ArticleComponents'

const data = {
  rows: [
    {
      cols: [
        {
          card: {
            id: 0,
            type: 'large',
            createdAt: 1704492000000,
            title: 'Boom',
            text: 'it’s you v. the world. are you game (or scared)?',
            image: BoomImg,
            node: (
              <>
                <Text>
                  Hey hey! 🌟 Guess what? We’ve just launched this dope feature called Boom, and let
                  me tell you, it's a game-changer! 🚀 🚀 🚀
                </Text>

                <Text>
                  Okay so, Boom lets you shake or bump your phones to share contacts effortlessly
                  with one person, or groups – and get this – it works no matter what device anybody
                  has! No more struggling with manual adds, QR codes, searches, whatever, it's all
                  about that shake and share life now. 🤝✨
                  <br />
                  <br />
                  We came up with this feature to make irl friending super easy. No more awkward
                  moments when you're trying to exchange contact info.
                  <br />
                  <br />
                  And here's the kicker – you can use it to notify people when you're nearby. Like,
                  say you're at your fav hangout and your squad is nearby. Just give your phone a
                  little shake, and boom, they get a notification that you're in the area! 📍 Or
                  sneak up to peeps and shake your phone to let them know you’re nearby. No more
                  texting, "wya?"
                  <br />
                  <br />
                  Peer is all about that seamless, on-the-fly connection. So, get with the times,
                  and let's shake it up! 📲💥
                  <br />
                  <br />
                  #ShakaBoom #ShakeAndShare #Boom
                </Text>
              </>
            ),
          },
        },
      ],
    },
    {
      cols: [
        {
          card: {
            id: 1,
            type: 'medium',
            createdAt: 1704492000000,
            title: 'Love Collection',
            image: LoveCollectionImg,
            text: 'it’s you v. the world. are you game (or scared)?',
            node: (
              <>
                <Text>
                  Hey fam!🌈💖Just wanted to drop a lovely announcement – introducing Peer’s new
                  emoji stack: Love Collection! 🥰✨ It's all about spreading those good vibes,
                  love, and sweetness to your homies and crushes alike. 💕
                </Text>

                <Text>
                  Okay so this collection is like the ultimate emoji buffet. It’s too cool for
                  school—wait, that’s not a saying anymore. Anyhow, I’m telling you, a state of the
                  art. Just check out the vibes you get from it and the love-loaded expressions.
                  There's everything from adorable heart-eyed smileys to chic roses, and even some
                  rare expressions you won't find anywhere else. 🌹💎
                  <br />
                  <br />
                  If you’re feeling on top of the world today, set your status to a starry-eyed
                  emoji. Missing someone? A sad but cute heartbreak emoji is the way to go. Whatever
                  the vibe, we got you covered! 🌟💔
                  <br />
                  <br />
                  And the coolest part – you can use these emojis in your chats or flaunt them as
                  your status! Let the world know you're all about that love life. Spread
                  positivity, good vibes, and let your friends know they're cherished. 🌍💞
                  <br />
                  <br />
                  Let’s gooo, don't be shy – hit up your emoji keyboard, grab the Love Collection,
                  and start sending those love-infused vibes to your squad. Because let's be real,
                  who doesn't want a little extra love in their day? 💖📲
                  <br />
                  <br />
                  #LoveCollection #SpreadLove #EmojiGameStrong 💪😍
                </Text>
              </>
            ),
          },
        },
        {
          card: {
            id: 2,
            type: 'medium',
            createdAt: 1704492000000,
            title: 'Live Location',
            image: LiveLocationImg,
            text: 'it’s you v. the world. are you game (or scared)?',
            // withImage: false,
            node: (
              <>
                <Text>
                  🌐✨ Got some exciting news – we’ve just dropped a killer feature that's gonna
                  level up your social game: Live-Location Sharing! 📍📲
                  <br />
                  <br />
                  Now you can seamlessly share your whereabouts with your ride-or-die crew, close
                  friends, and family. It's all about staying connected without the hassle 💯
                  <br />
                  <br />
                  And it gets even better – we've added a slick group feature. Imagine this: you're
                  out with your squad, exploring the city or hitting up your fave spots. Just drop
                  everyone in a group and decide the level of privacy you're comfortable with. It's
                  like creating your own little VIP location-sharing party! 🎉🌍
                  <br />
                  <br />
                  And here's the game-changer – our rock-solid, super-efficient location algorithm.
                  We get it – you don't want your battery life taking a nosedive every time you
                  share your location. That's why our algorithm is on point, giving you accuracy
                  without turning your battery into a snack. 🚀🔋 We're all about saving that
                  battery life while keeping you connected. No more worrying about your phone dying
                  mid-adventure – we got your back! ⚡️
                  <br />
                  <br />
                  So, whether you're coordinating a meet-up, ensuring your besties make it to the
                  party, or just keeping tabs on your crew, Live-Location Sharing is the move. Stay
                  connected, stay chill, and let the good times roll! 🤙🗺️
                  <br />
                  <br />
                  #LocationVibes #StayConnected #NoMoreLostSquads 🌐👫
                </Text>
              </>
            ),
          },
        },
        {
          card: {
            id: 3,
            type: 'medium',
            createdAt: 1704492000000,
            title: 'All-New Chat',
            text: 'it’s you v. the world. are you game (or scared)?',
            image: AllNewChatImg,
            node: (
              <>
                <Text>
                  🌐✨ Got some exciting news – we’ve just dropped a killer feature that's gonna
                  level up your social game: Live-Location Sharing! 📍📲
                  <br />
                  <br />
                  Now you can seamlessly share your whereabouts with your ride-or-die crew, close
                  friends, and family. It's all about staying connected without the hassle 💯
                  <br />
                  <br />
                  And it gets even better – we've added a slick group feature. Imagine this: you're
                  out with your squad, exploring the city or hitting up your fave spots. Just drop
                  everyone in a group and decide the level of privacy you're comfortable with. It's
                  like creating your own little VIP location-sharing party! 🎉🌍
                  <br />
                  <br />
                  And here's the game-changer – our rock-solid, super-efficient location algorithm.
                  We get it – you don't want your battery life taking a nosedive every time you
                  share your location. That's why our algorithm is on point, giving you accuracy
                  without turning your battery into a snack. 🚀🔋 We're all about saving that
                  battery life while keeping you connected. No more worrying about your phone dying
                  mid-adventure – we got your back! ⚡️
                  <br />
                  <br />
                  So, whether you're coordinating a meet-up, ensuring your besties make it to the
                  party, or just keeping tabs on your crew, Live-Location Sharing is the move. Stay
                  connected, stay chill, and let the good times roll! 🤙🗺️
                  <br />
                  <br />
                  #LocationVibes #StayConnected #NoMoreLostSquads 🌐👫
                </Text>
              </>
            ),
          },
        },
      ],
    },
    {
      cols: [
        {
          card: {
            id: 4,
            type: 'big',
            createdAt: 1704492000000,
            title: 'Lunar New Year',
            text: 'it’s you v. the world. are you game (or scared)?',
            image: LunarNewYearImg,
            node: (
              <>
                <Text>
                  Hey moji fam! 🎊✨ Get ready to usher in the Lunar New Year like never before,
                  'cause we're dropping something special – our Lunar New Year Moji Collection! 🐉🧧
                  <br />
                  <br />
                  As we gear up to celebrate the Year of the Dragon in 2024, it's all about
                  spreading good vibes, health, and wealth your way. And what better way to do it
                  than with our auspicious mojis! 🌟💰
                  <br />
                  <br />
                  Whether you're wishing prosperity, sending good cheer, or just vibing with the
                  festive spirit, our Lunar New Year Moji Collection has got you covered. Send it to
                  everybody! From dragons dancing in delight to lucky red envelopes bursting with
                  fortune, these mojis are bringing all the positive energy your chats need! And the
                  Lunar New Year is celebrated over a long stretch. It’s like 7 days before the
                  first and sometimes up to 20 days afterwards in Vietnam lol 🧨💚
                  <br />
                  <br />
                  So, as you come together with friends and family to celebrate, let our mojis do
                  the talking. Share the joy, the laughter, and the wishes for a prosperous and
                  healthy year ahead. But you don’t have to be celebrating, because these mojis are
                  super cool. Just send away! It's all about that sweet moji magic! 🌈🎇
                  <br />
                  <br />
                  May your Year of the Dragon be filled with good fortune, abundant health, and
                  endless wealth! 🐉💖 Gong Xi Fa Cai, Chúc Mừng Năm Mới y'all! 🧧🎉
                  <br />
                  <br />
                  #LunarNewYearMoji #YearOfTheDragon #GoodVibesOnly 🌙🧡
                </Text>
              </>
            ),
          },
        },
        {
          card: {
            id: 5,
            type: 'big',
            createdAt: 1704492000000,
            title: '3D Maps ',
            text: 'it’s you v. the world. are you game (or scared)?',
            image: ThreeDMapsImg,
            node: (
              <>
                <Text>
                  Hey moji fam! 🎊✨ Get ready to usher in the Lunar New Year like never before,
                  'cause we're dropping something special – our Lunar New Year Moji Collection! 🐉🧧
                  <br />
                  <br />
                  As we gear up to celebrate the Year of the Dragon in 2024, it's all about
                  spreading good vibes, health, and wealth your way. And what better way to do it
                  than with our auspicious mojis! 🌟💰
                  <br />
                  <br />
                  Whether you're wishing prosperity, sending good cheer, or just vibing with the
                  festive spirit, our Lunar New Year Moji Collection has got you covered. Send it to
                  everybody! From dragons dancing in delight to lucky red envelopes bursting with
                  fortune, these mojis are bringing all the positive energy your chats need! And the
                  Lunar New Year is celebrated over a long stretch. It’s like 7 days before the
                  first and sometimes up to 20 days afterwards in Vietnam lol 🧨💚
                  <br />
                  <br />
                  So, as you come together with friends and family to celebrate, let our mojis do
                  the talking. Share the joy, the laughter, and the wishes for a prosperous and
                  healthy year ahead. But you don’t have to be celebrating, because these mojis are
                  super cool. Just send away! It's all about that sweet moji magic! 🌈🎇
                  <br />
                  <br />
                  May your Year of the Dragon be filled with good fortune, abundant health, and
                  endless wealth! 🐉💖 Gong Xi Fa Cai, Chúc Mừng Năm Mới y'all! 🧧🎉
                  <br />
                  <br />
                  #LunarNewYearMoji #YearOfTheDragon #GoodVibesOnly 🌙🧡
                </Text>
              </>
            ),
          },
        },
      ],
    },
    // {
    //   cols: [
    //     {
    //       card: {
    //         id: 6,
    //         type: 'big',
    //         createdAt: 1704492000000,
    //         title: 'Posting Images',
    //         text: 'it’s you v. the world. are you game (or scared)?',
    //         image: PostingImagesImg,
    //         node: (
    //           <>
    //             <Text>
    //               Hey moji fam! 🎊✨ Get ready to usher in the Lunar New Year like never before,
    //               'cause we're dropping something special – our Lunar New Year Moji Collection! 🐉🧧
    //               <br />
    //               <br />
    //               As we gear up to celebrate the Year of the Dragon in 2024, it's all about
    //               spreading good vibes, health, and wealth your way. And what better way to do it
    //               than with our auspicious mojis! 🌟💰
    //               <br />
    //               <br />
    //               Whether you're wishing prosperity, sending good cheer, or just vibing with the
    //               festive spirit, our Lunar New Year Moji Collection has got you covered. Send it to
    //               everybody! From dragons dancing in delight to lucky red envelopes bursting with
    //               fortune, these mojis are bringing all the positive energy your chats need! And the
    //               Lunar New Year is celebrated over a long stretch. It’s like 7 days before the
    //               first and sometimes up to 20 days afterwards in Vietnam lol 🧨💚
    //               <br />
    //               <br />
    //               So, as you come together with friends and family to celebrate, let our mojis do
    //               the talking. Share the joy, the laughter, and the wishes for a prosperous and
    //               healthy year ahead. But you don’t have to be celebrating, because these mojis are
    //               super cool. Just send away! It's all about that sweet moji magic! 🌈🎇
    //               <br />
    //               <br />
    //               May your Year of the Dragon be filled with good fortune, abundant health, and
    //               endless wealth! 🐉💖 Gong Xi Fa Cai, Chúc Mừng Năm Mới y'all! 🧧🎉
    //               <br />
    //               <br />
    //               #LunarNewYearMoji #YearOfTheDragon #GoodVibesOnly 🌙🧡
    //             </Text>
    //           </>
    //         ),
    //       },
    //     },
    //     {
    //       card: {
    //         id: 7,
    //         type: 'big',
    //         createdAt: 1704492000000,
    //         title: 'Freemium Emojis / Unlockables',
    //         text: 'it’s you v. the world. are you game (or scared)?',
    //         image: FreemiumEmojisImg,
    //         node: (
    //           <>
    //             <Text>
    //               Hey moji fam! 🎊✨ Get ready to usher in the Lunar New Year like never before,
    //               'cause we're dropping something special – our Lunar New Year Moji Collection! 🐉🧧
    //               <br />
    //               <br />
    //               As we gear up to celebrate the Year of the Dragon in 2024, it's all about
    //               spreading good vibes, health, and wealth your way. And what better way to do it
    //               than with our auspicious mojis! 🌟💰
    //               <br />
    //               <br />
    //               Whether you're wishing prosperity, sending good cheer, or just vibing with the
    //               festive spirit, our Lunar New Year Moji Collection has got you covered. Send it to
    //               everybody! From dragons dancing in delight to lucky red envelopes bursting with
    //               fortune, these mojis are bringing all the positive energy your chats need! And the
    //               Lunar New Year is celebrated over a long stretch. It’s like 7 days before the
    //               first and sometimes up to 20 days afterwards in Vietnam lol 🧨💚
    //               <br />
    //               <br />
    //               So, as you come together with friends and family to celebrate, let our mojis do
    //               the talking. Share the joy, the laughter, and the wishes for a prosperous and
    //               healthy year ahead. But you don’t have to be celebrating, because these mojis are
    //               super cool. Just send away! It's all about that sweet moji magic! 🌈🎇
    //               <br />
    //               <br />
    //               May your Year of the Dragon be filled with good fortune, abundant health, and
    //               endless wealth! 🐉💖 Gong Xi Fa Cai, Chúc Mừng Năm Mới y'all! 🧧🎉
    //               <br />
    //               <br />
    //               #LunarNewYearMoji #YearOfTheDragon #GoodVibesOnly 🌙🧡
    //             </Text>
    //           </>
    //         ),
    //       },
    //     },
    //   ],
    // },
  ],
}

export default data
