const desktop = {
  withoutAuth: {
    home: '/',
    app: '/app',
    notFound: '/notFound',
    homeProduct: '/product',
    team: '/team',
    jobs: '/careers',
    shop: '/shop',
    blog: '/blog',
  },
  withAuth: {},
}

export default desktop
