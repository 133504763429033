/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */

export function IconLocation() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.551 5.03201C10.0865 5.03201 8.08865 7.02987 8.08865 9.49436C8.08865 11.9588 10.0865 13.9567 12.551 13.9567C15.0155 13.9567 17.0133 11.9588 17.0133 9.49436C17.0133 7.02987 15.0155 5.03201 12.551 5.03201ZM6.30371 9.49436C6.30371 6.04408 9.10072 3.24707 12.551 3.24707C16.0013 3.24707 18.7983 6.04408 18.7983 9.49436C18.7983 12.6416 16.471 15.2453 13.4435 15.6784V20.0924C13.4435 20.5853 13.0439 20.9849 12.551 20.9849C12.0581 20.9849 11.6585 20.5853 11.6585 20.0924V15.6784C8.63101 15.2453 6.30371 12.6416 6.30371 9.49436ZM12.551 12.1718C14.0297 12.1718 15.2284 10.9731 15.2284 9.49436C15.2284 8.01567 14.0297 6.81695 12.551 6.81695C11.0723 6.81695 9.87359 8.01567 9.87359 9.49436C9.87359 10.9731 11.0723 12.1718 12.551 12.1718Z"
        fill="white"
      />
    </svg>
  )
}

export function IconDownload() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.945 8.84152C17.4253 8.30361 16.6457 8.1243 16.0394 8.66221L14.2204 10.0966V4.63969C14.2204 3.83283 13.6141 3.29492 12.9212 3.29492L12.055 3.29492C11.2754 3.29492 10.7557 3.92248 10.7557 4.63969L10.7557 10.0966L8.93676 8.66221C8.33044 8.21395 7.46426 8.30361 7.03117 8.93117L6.51147 9.64838C6.07838 10.2759 6.165 11.0828 6.77132 11.6207L11.4487 15.3861C12.055 15.924 12.9212 15.924 13.6141 15.3861L18.2048 11.5311C18.8112 11.0828 18.8978 10.1863 18.4647 9.55873L17.945 8.84152Z"
        fill="currentColor"
      />
      <path
        d="M5.26465 15.5943C5.26465 14.9473 5.78909 14.4229 6.43602 14.4229H7.21693C7.86385 14.4229 8.38829 14.9473 8.38829 15.5943V18.9131C8.38829 19.3444 8.73792 19.6941 9.1692 19.6941H15.8069C16.2382 19.6941 16.5879 19.3444 16.5879 18.9131V15.5943C16.5879 14.9473 17.1123 14.4229 17.7592 14.4229H18.5401C19.1871 14.4229 19.7115 14.9473 19.7115 15.5943V21.6463C19.7115 22.2933 19.1871 22.8177 18.5401 22.8177H6.43602C5.78909 22.8177 5.26465 22.2933 5.26465 21.6463V15.5943Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function IconPlay() {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_iii_1_38)">
        <circle cx="42" cy="41.9995" r="42" fill="#D2D3DA" />
      </g>
      <path
        d="M54.855 39.5721L36.0336 28.8634C33.7883 27.5859 31 29.2075 31 31.7908V53.2082C31 55.7915 33.7883 57.413 36.0336 56.1356L54.855 45.4268C57.1249 44.1353 57.1249 40.8636 54.855 39.5721Z"
        fill="black"
      />
      <defs>
        <filter
          id="filter0_iii_1_38"
          x="0"
          y="-4.00052"
          width="84"
          height="92"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2" />
          <feGaussianBlur stdDeviation="3.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_38" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.984314 0 0 0 0 0.984314 0 0 0 0 0.984314 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="effect1_innerShadow_1_38" result="effect2_innerShadow_1_38" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect2_innerShadow_1_38" result="effect3_innerShadow_1_38" />
        </filter>
      </defs>
    </svg>
  )
}
